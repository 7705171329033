import React from "react"
import "./LoadingIcon.css"

import LoadingImage from "../../assets/Bunch-Logo/Favicon/Colour/Bunch_Favicon_3.png"

function LoadingIcon() {
  return <img alt="Loading" id="loading-icon" src={LoadingImage} />
}

export default LoadingIcon
